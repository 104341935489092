import React, { useState } from 'react'
import Autocomplete from 'react-autocomplete'
import { APIProvider, Map, Marker, ControlPosition, MapControl } from '@vis.gl/react-google-maps';
import './GoogleMaps.css'

const GoogleMaps = ({
  latLng,
  mLatLng,
  setLatLng,
  setMLatLng,
  setLocationDetails,
  fetchAutoComplete,
  fetchPlaceDetails,
  selectedLocationVal,
  setSelectedLocationVal,
  isEditable
}) => {
  let autocompleteTimer

  const [locations, setLocations] = useState([])
  const [zoom, setZoom] = useState(15)

  const handleFetchAutoComplete = async (query) => {
    try {
      const response = await fetchAutoComplete(query)
      
      if (response && response.data) {
        setLocations(response.data && response.data.predictions)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleFetchPlaceDetails = async (placeId) => {
    try {
      const response = await fetchPlaceDetails(placeId)
      if (response && response.data) {
        if (response?.data?.status == 'OK') {
          const result = response.data && response.data.result
          const geometry = result && result.geometry
          const lat = geometry && geometry.location && geometry.location.lat
          const lng = geometry && geometry.location && geometry.location.lng
          setLocationDetails(result && result.formatted_address)
          setLatLng({ lat, lng })
          setMLatLng({ mLat: lat, mLng: lng })
          setZoom(18)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleAutocomplete = (e) => {
    const value = e && e.target && e.target.value
    setSelectedLocationVal(value)
    if (value && value.length > 2) {
      if (autocompleteTimer) {
        clearTimeout(autocompleteTimer)
      }
      autocompleteTimer = setTimeout(() => handleFetchAutoComplete(value), 1000)
    }
  }

  const handleSelectedItem = (value, item) => {
    setSelectedLocationVal(value)
    handleFetchPlaceDetails(item && item.place_id)
  }

  const handleMapChange = (e) => {
    const details = e.detail
    const lat = details && details.center && details.center.lat
    const lng = details && details.center && details.center.lng
    const zm = details && details.zoom
    
    if (isEditable) {
      setLatLng({ lat, lng })
      setZoom({ zoom: zm })
    }
  }

  const handleMarkerDrag = (e) => {
    const details = e && JSON.parse(JSON.stringify(e))
    const lat = details.latLng && details.latLng.lat
    const lng = details.latLng && details.latLng.lng
    setLatLng({ lat, lng })
    setMLatLng({ mLat: lat, mLng: lng })
  }

  const handleMapClick = async (e) => {
    const { detail } = e
    const { placeId } = detail || {}

    if (isEditable) {
      await handleFetchPlaceDetails(placeId)
    }
  }
  
  return (
    <div className='header-container'>
      <div className='main-container'>
        <APIProvider apiKey={'AIzaSyBsDDGDk6wY513oK7aYQ7Q_EJtpeF-T9UQ'}>
          <Map
            style={{width: '65vw', height: '500px'}}
            onCameraChanged={handleMapChange}
            defaultCenter={{
              lat: latLng && latLng.lat || 0,
              lng: latLng && latLng.lng || 0
            }}
            center={{
              lat: latLng && latLng.lat || 0,
              lng: latLng && latLng.lng || 0
            }}
            defaultZoom={zoom}
            zoom={zoom}
            controlled={false}
            gestureHandling={'none'}
            disableDefaultUI={true}
            onClick={handleMapClick}
          >
            <Marker
              position={{
                lat: mLatLng.mLat || 0,
                lng: mLatLng.mLng || 0
              }}
              draggable={isEditable}
              onDragEnd={handleMarkerDrag}
            />
          </Map>
          {
            isEditable === true && (
              <MapControl position={ControlPosition.TOP_CENTER}>
                <Autocomplete
                  className="mb-4"
                  getItemValue={(item) => item.description}
                  items={locations}
                  renderItem={(item, isHighlighted) =>
                    <div key={item.place_id} className={`${isHighlighted ? "bg-gray-200" : "bg-white"} fs-6`} style={{ fontFamily: '"Poppins", sans-serif', width: '35vw', cursor: 'pointer' }}>
                      {item.description}
                    </div>
                  }
                  renderInput={(props) =>
                    <textarea rows={1} placeholder="Search your street address" className="mt-6 fs-6 p-2" style={{ fontFamily: '"Poppins", sans-serif', width: '35vw', border: '1px solid black', borderRadius: '5px' }} {...props} />
                  }
                  value={selectedLocationVal}
                  onChange={handleAutocomplete}
                  onSelect={handleSelectedItem}
                />
              </MapControl>
            )
          }
        </APIProvider>
      </div>
    </div>
  );
}

export default GoogleMaps;