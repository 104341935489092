export const productStatus = (status) => {
  switch (status) {
    case 'active':
      return 'success'
    case 'low in stock':
      return 'warning'
    case 'out of stock':
      return 'danger'
    case 'draft':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const orderStatus = (status) => {
  switch (status) {
    case 'success':
    case 'completed':
    case 'delivered':
      return 'success'
    case 'confirmed':
    case 'allocated':
    case 'shipped':
    case 'picking_up':
    case 'picked': 
      return 'info'
    case 'return_in_transit':
    case 'returned':
      return 'warning'
    case 'dropping_off':
      return 'primary'
    case 'pending':
      return 'secondary'
    case 'on_hold':
      return 'dark'
    case 'failed':
    case 'rejected':
    case 'cancelled':
    case 'disposed':
    case 'courier_not_found':
    return 'danger'
    default:
      return 'secondary'
  }
}

export const shippingStatus = (status) => {
  switch (status) {
    case 'confirmed':
      return 'Order has been confirmed. Locating nearest driver to pick up.'
    case 'allocated':
      return 'Courier has been allocated. Waiting to pick up.'
    case 'picking_up':
      return 'Courier is on the way to pick up item.'
    case 'picked':
      return 'Order has been picked and courier ready to be shipped.'
    case 'dropping_off':
      return 'Order is on the way to customer.'
    case 'return_in_transit':
      return 'Order is on the way back to the origin.'
    case 'on_hold':
      return "Your shipment is on hold at the moment. We'll ship your item after it's resolved."
    case 'delivered':
      return 'Item has been delivered.'
    case 'rejected':
      return 'Your shipment has been rejected. Please contact Biteship for more information.'
    case 'courier_not_found':
      return "Your shipment is canceled because there's no courier available at the moment."
    case 'returned':
      return 'Order successfully returned.'
    case 'cancelled':
      return 'Order is cancelled.'
    case 'disposed':
      return 'Order successfully disposed.'
    default:
      return '-'
  }
}
