import React from 'react'
import moment from 'moment'

// components
import {
  CButton,
  CCol,
  CFormInput,
  CRow
} from '@coreui/react-pro'
import AppModal from '../AppModal'

// icons
import CIcon from '@coreui/icons-react'
import { cilWarning, cilTruck, cilArrowThickBottom } from '@coreui/icons'

// styling
import { styleButtonVars3 } from '../custom-styles/buttonStyles'

// helpers
import { shippingStatus } from 'src/helpers/status-badge'

const AppModalConfirmation = ({
  confirmationType,
  itemName,
  itemDetails,
  itemType,
  showModal,
  setShowModal,
  handleConfirmation,
  values,
  errors,
  handleChange
}) => {

  return (
    <>
      {confirmationType === 'delete-item' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to delete this ${itemName}?`}
          bodyDescription={`Once confirmed, it can not be undo and all ${itemName} details will be deleted permanently.`}
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, item: {} })}>
                No
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation(itemDetails, itemType)}>
                Yes
              </CButton>
            </>
          }
        />
      )}
      {confirmationType === 'request-shipment' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to request pickup?`}
          bodyDescription={
            <p>
              Once confirmed, it can not be undo and all {itemName} details will be updated permanently.
            </p>
          }
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal(false)}>
                NO
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation()}>
                YES
              </CButton>
            </>
          }
        />
      )}
      {
        confirmationType === 'check-history-shipping' && (
          <AppModal
            showModal={showModal}
            setShowModal={setShowModal}
            bodyTitleIcon={<CIcon icon={cilTruck} size='8xl' />}
            bodyTitle={`History of Shipping`}
            bodyDescription={
              <CRow className='justify-content-center'>
                <div className='col-6'>
                  <div className='text-start'>Courier: {values?.courier?.company?.toUpperCase()}</div>
                  <div className='mb-3 text-start'>Status: {shippingStatus(values?.status)}</div>
                </div>
                <div>
                  <span>History:</span>
                  {
                    values?.history?.length > 0 && (
                      values?.history?.map((item, index) => {
                        return (
                          <CRow className='justify-content-center'>
                            <div className='col-6 border-start border-start-primary mb-2 p-4 callout callout-secondary'>
                              <div className='row mb-2'>
                                <div className='fs-6 fw-light text-start'>
                                  {moment(item?.updated_at).format('DD MMMM HH:mm')} WIB
                                </div>
                              </div>
                              <div className='row'>
                                <div className='text-start fw-bold'>
                                  {item?.note}
                                </div>
                              </div>
                            </div>
                            {
                              index !== values?.history.length - 1 && (
                                <div className='col-8'>
                                  <CIcon icon={cilArrowThickBottom} size='2xl' />
                                </div>
                              )
                            }
                          </CRow>
                        )
                      })
                    )
                  }
                </div>
              </CRow>
            }
          />
        )
      }
      {confirmationType === 'completed-order' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to complete this ${itemName}?`}
          bodyDescription={
            <CRow className='align-items-center justify-content-center'>
              <p>
                Please input order status below:
              </p>
              <CFormInput
                id='payment_status'
                name='payment_status'
                className='mb-2'
                style={{ width: '20rem' }}
                placeholder='Enter order status...'
                type="text"
                value={values.payment_status}
                onChange={(e) => handleChange(e)}
                feedbackInvalid={errors.payment_status}
                invalid={errors.payment_status}
              />
              <p>
                Once confirmed, it can not be undo and all {itemName} details will be updated permanently.
              </p>
            </CRow>
          }
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, status: '' })}>
                Cancel
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation()}>
                Submit
              </CButton>
            </>
          }
        />
      )}
    </>
  )
}

export default AppModalConfirmation
