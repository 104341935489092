import React, { useEffect, useState } from 'react'

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import AddMoreIconWhite from '../../assets/icons/AddMoreIconWhite.png'
import Shirt1 from '../../assets/images/Shirt1.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnProductList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const ProductList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [productList, setProductList] = useState([]);
  const [productListCount, setProductListCount] = useState(0);
  const [productListPerPage, setProductListPerPage] = useState(10);
  const [activeProductListPage, setActiveProductListPage] = useState(1);
  const [searchProductListFilter, setSearchProductListFilter] = useState("");
  const [columnProductListSorter, setColumnProductListSorter] = useState({ column: "createdAt", state: "DESC" });

  useEffect(() => {
    let queries = {
      search: searchProductListFilter,
      limit: productListPerPage,
      offset: productListPerPage * activeProductListPage - productListPerPage,
      sort:
        (columnProductListSorter &&
          `${columnProductListSorter.column}%${columnProductListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllProducts(queries);
  }, [
    searchProductListFilter,
    productListPerPage,
    activeProductListPage,
    columnProductListSorter,
  ]);

  const handleSearchItems = (search) => {
    setActiveProductListPage(1)
    setSearchProductListFilter(search);
  }

  const fetchAllProducts = (queries) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/products/admin?${queries}`,
    })
      .then(({ data }) => {
        const tempProductList = data?.products?.map((d) => ({
          ...d,
          product_main_image: d?.images[0]?.image_url,
          category_name: d?.sub_category?.category?.name || 'Not Available',
          brand_name: d?.brand?.name || 'Not Available'
        }));

        setProductList(tempProductList);
        if (activeProductListPage === 1) {
          setProductListCount(data?.count);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/products/delete/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='product'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/products/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Add Product
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Product List</h4>}
        bodyContent={
          <AppTable
            productListPage
            selectable
            tableFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnProductList}
            tableContent={productList}
            contentPerPage={productListPerPage}
            activeContentPage={activeProductListPage}
            contentCount={productListCount}
            searchContentFilter={searchProductListFilter}
            setActiveContentPage={setActiveProductListPage}
            setSearchContentFilter={handleSearchItems}
            setContentPerPage={setProductListPerPage}
            setColumnContentSorter={setColumnProductListSorter}
          />
        }
      />
    </>
  )
}

export default ProductList
