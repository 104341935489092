
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

// components
import {
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import Axios from 'src/helpers/axios'
import { formatPriceNumber } from 'src/helpers/format-data'

const PurchaseDetails = ({
  orderDetails,
  shippingAddressDetails
}) => {
  const currency = useSelector((state) => state.currency)
  const [subDistrictDetails, setSubDistrictDetails] = useState({})

  useEffect(() => {
    if (shippingAddressDetails && shippingAddressDetails.subdistrict_id) {
      fetchSubDistrictDetails(shippingAddressDetails.subdistrict_id)
    }
  }, [shippingAddressDetails]);

  const fetchSubDistrictDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/countries/subdistricts/details/${id}`,
    })
      .then(({ data }) => {
        setSubDistrictDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const orderMetadata = orderDetails?.products?.[0] ? JSON.parse(orderDetails?.products?.[0]?.metadata) : {}

  return (
    <>
      <CRow>
        <CCol lg='6' className="mb-3">
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title={`Invoice ${orderDetails?.invoice_id ? orderDetails?.invoice_id : ""}`}
          />
          {orderDetails?.products?.length > 0 && (
            <CTable borderless>
              <CTableBody>
                {orderDetails?.products?.map((purchase, index) => {
                  // const product_original_price = purchase?.product?.price
                  // const product_discount = purchase?.product?.product_discounts?.[0]
                  // const product_price = product_discount ? (product_discount?.value_discount ? product_original_price - product_discount?.value_discount : product_original_price - (product_original_price * product_discount?.percentage_discount / 100)) : product_original_price

                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{`${purchase?.qty} x ${purchase?.product_name}`}</CTableDataCell>
                      <CTableDataCell>{formatPriceNumber(purchase?.product_price, currency?.code)}</CTableDataCell>
                    </CTableRow>
                  )
                })}
                <br />
                <CTableRow>
                  <CTableDataCell>Tax (11%)</CTableDataCell>
                  <CTableDataCell>{orderMetadata?.pricing?.tax ? formatPriceNumber(orderMetadata?.pricing?.tax, currency?.code) : "-"}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Shipping Fee</CTableDataCell>
                  <CTableDataCell>{orderDetails?.shipping_cost ? formatPriceNumber(orderDetails?.shipping_cost, currency?.code) : 'FREE'}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Transaction Fee</CTableDataCell>
                  <CTableDataCell>{orderMetadata?.pricing?.transaction_fee ? formatPriceNumber(orderMetadata?.pricing?.transaction_fee, currency?.code) : "-"}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Transaction Tax</CTableDataCell>
                  <CTableDataCell>{orderMetadata?.pricing?.transaction_tax ? formatPriceNumber(orderMetadata?.pricing?.transaction_tax, currency?.code) : "-"}</CTableDataCell>
                </CTableRow>
                {
                  orderMetadata?.pricing?.discount > 0 && (
                    <CTableRow>
                      <CTableDataCell>Voucher</CTableDataCell>
                      <CTableDataCell>{orderMetadata?.pricing?.discount ? `- ${formatPriceNumber(orderMetadata?.pricing?.discount, currency?.code)}` : "-"}</CTableDataCell>
                    </CTableRow>
                  )
                }
                <CTableRow>
                  <CTableDataCell><b>Total</b></CTableDataCell>
                  <CTableDataCell><b>{formatPriceNumber(orderDetails?.transaction_amount, currency?.code)}</b></CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          )}
        </CCol>
        <CCol lg='6' className="mb-3">
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Phone Number"
              subtitle={shippingAddressDetails?.phone_number || shippingAddressDetails?.contact_information_phone_number}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Email Address"
              subtitle={orderDetails?.customer_email?.email}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Address"
              subtitle={`${shippingAddressDetails?.street_address} ${shippingAddressDetails?.address_details} ${subDistrictDetails?.subdistrict_name}, ${subDistrictDetails?.city}, ${subDistrictDetails?.province} ${shippingAddressDetails?.country?.name} ${shippingAddressDetails?.postal_code}`}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Shipping Method"
              subtitle={`${orderDetails?.shipping_method?.name ? `${orderDetails?.shipping_method?.name} - ${orderDetails?.shipping_service || ""}` : `${orderMetadata?.shipment?.shipping_method} - ${orderMetadata?.shipment?.shipping_service}`}`}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Payment Type"
              subtitle={orderDetails?.payment_channel?.name}
            />
          </CRow>
        </CCol>
      </CRow>
    </>
  )
}

export default PurchaseDetails
