import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard'
import AppTable from 'src/components/AppTable'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// helpers
import { columnOrderList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const OrderList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [orderList, setOrderList] = useState([]);
  const [orderListCount, setOrderListCount] = useState(0);
  const [orderListPerPage, setOrderListPerPage] = useState(10);
  const [activeOrderListPage, setActiveOrderListPage] = useState(1);
  const [searchOrderListFilter, setSearchOrderListFilter] = useState("");
  const [columnOrderListSorter, setColumnOrderListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    // let queries = {
    //   search: searchOrderListFilter,
    //   // limit: orderListPerPage,
    //   offset: orderListPerPage * activeOrderListPage - orderListPerPage,
    //   sort:
    //     (columnOrderListSorter &&
    //       `${columnOrderListSorter.column}%${columnOrderListSorter.state}`) ||
    //     "",
    // };
    // queries = formUrlQuery(queries);
    const payload = {
      search: searchOrderListFilter,
      limit: orderListPerPage,
      offset: orderListPerPage * activeOrderListPage - orderListPerPage,
      order: ['createdAt', 'desc']
    }
    fetchAllOrders(payload);
  }, [
    searchOrderListFilter,
    orderListPerPage,
    activeOrderListPage,
    columnOrderListSorter,
  ]);

  const handleSearchItems = (search) => {
    setActiveOrderListPage(1)
    setSearchOrderListFilter(search);
  }

  const fetchAllOrders = (payload) => {
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/transactions/all`,
      data: payload
    })
      .then(({ data }) => {
        const tempOrderList = data?.transactions?.map((d) => ({
          ...d,
          customer_name: d?.customer_email?.email || 'Not Available',
          payment_type: d?.payment_channel?.name || '-',
          shipping_company: d?.shipping_method?.name || "-"
        }));

        setOrderList(tempOrderList);
        if (activeOrderListPage === 1) {
          setOrderListCount(data?.count);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/transactions/delete/id/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='order'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Order List</h4>}
        bodyContent={
          <AppTable
            orderListPage
            selectable
            tableFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnOrderList}
            tableContent={orderList}
            contentPerPage={orderListPerPage}
            activeContentPage={activeOrderListPage}
            contentCount={orderListCount}
            searchContentFilter={searchOrderListFilter}
            setActiveContentPage={setActiveOrderListPage}
            setSearchContentFilter={handleSearchItems}
            setContentPerPage={setOrderListPerPage}
            setColumnContentSorter={setColumnOrderListSorter}
          />
        }
      />
    </>
  )
}

export default OrderList
