import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/update-my-page/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'
import GoogleMaps from 'src/components/GoogleMaps'

// helpers
import Axios from 'src/helpers/axios'
import {
  singleOptionsSelect,
  singleCountriesOptionsSelect,
  singleCurrencyOptionsSelect,
  singleProvinceOptionsSelect,
  singleCityOptionsSelect,
  singleSubDistrictOptionsSelect
} from 'src/helpers/format-options'

const EditMyPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [generalSettingDetails, setGeneralSettingDetails] = useState({})
  const [countryOptions, setCountryOptions] = useState([])
  const [provinceOptions, setProvinceOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [subDistrictOptions, setSubDistrictOptions] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [phoneCountry, setPhoneCountry] = useState({})
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Gmaps state
  const [latLng, setLatLng] = useState({ lat: -6.1753917, lng: 106.8271533 }) // Pinpoint Location DKI Jakarta
  const [mLatLng, setMLatLng] = useState({ mLat: -6.1753917, mLng: 106.8271533 })  // Pinpoint Location DKI Jakarta
  const [locationDetails, setLocationDetails] = useState('')
  const [selectedLocationVal, setSelectedLocationVal] = useState('')

  const formik = useFormik({
    initialValues: {
      store_name: generalSettingDetails?.shop_name,
      store_currency_id: generalSettingDetails?.shop_currency_id,
      store_person_name: generalSettingDetails?.contact_person_name,
      store_person_email: generalSettingDetails?.contact_person_email,
      store_person_number: generalSettingDetails?.contact_person_number,
      store_address: generalSettingDetails?.address_details,
      store_country_id: generalSettingDetails?.country_id,
      store_postal_code: generalSettingDetails?.postal_code,
      store_province_id: generalSettingDetails?.province_id,
      store_city_id: generalSettingDetails?.city_id,
      store_subdistrict_id: generalSettingDetails?.subdistrict_id,
      store_logo: generalSettingDetails?.logo_url || "",
      store_street_address: generalSettingDetails?.street_address,
      store_latitude_marker: generalSettingDetails?.latitude_marker,
      store_longitude_marker: generalSettingDetails?.longitude_marker,
      store_latitude_map: generalSettingDetails?.latitude_map,
      store_longitude_map: generalSettingDetails?.longitude_map
    },
    validationSchema: Yup.object().shape({
      store_name: Yup.string().required("Please enter store name."),
      store_currency_id: Yup.string().notOneOf(['Please select...'], 'Please enter store currency').required("Please enter store currency"),
      store_person_name: Yup.string().required("Please enter store contact name"),
      store_person_email: Yup.string().email("Invalid email format").required("Please enter store contact email."),
      store_person_number: Yup.string().required("Please enter store contact number"),
      store_address: Yup.string().required("Please enter store address"),
      store_country_id: Yup.string().notOneOf(['Please select...'], 'Please enter store country').required("Please enter store country"),
      store_postal_code: Yup.string().required("Please enter store postal code"),
      store_province_id: Yup.string()
        .when('store_country_id', {
          is: value => parseInt(value) === 104,
          then: () => Yup.string().notOneOf(['Please select...'], 'Please enter store province').required("Please enter store province")
        }),
      store_city_id: Yup.string()
        .when('store_country_id', {
          is: value => parseInt(value) === 104,
          then: () => Yup.string().notOneOf(['Please select...'], 'Please enter store city').required("Please enter store city")
        }),
      store_subdistrict_id: Yup.string()
        .when('store_country_id', {
          is: value => parseInt(value) === 104,
          then: () => Yup.string().notOneOf(['Please select...'], 'Please enter store subdistrict').required("Please enter store subdistrict")
        }),
      store_logo: Yup.mixed()
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchGeneralSettingsUser()
    fetchAllCountries()
    fetchAllCurrencies()
  }, []);

  useEffect(() => {
    if (formik?.values?.store_country_id) {
      fetchAllProvinces(formik?.values?.store_country_id)
    }
  }, [formik?.values?.store_country_id])

  useEffect(() => {
    if (formik?.values?.store_country_id && formik.values.store_province_id && formik.values.store_province_id !== 'Please select...') {
      fetchAllCities(formik?.values?.store_country_id, formik.values.store_province_id)
    }
  }, [formik.values.store_province_id]);

  useEffect(() => {
    if (formik?.values?.store_country_id && formik.values.store_city_id && formik.values.store_city_id !== 'Please select...') {
      fetchAllSubDistricts(formik?.values?.store_country_id, formik.values.store_city_id)
    }
  }, [formik.values.store_city_id]);

  useEffect(() => {
    if (formik.values.store_country_id && formik.values.store_country_id !== 'Please select...') {
      fetchCountryDetails(formik.values.store_country_id)
    }
  }, [formik.values.store_country_id]);

  const fetchGeneralSettingsUser = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/settings`,
    })
      .then(({ data }) => {
        let generalSettingsDetail = data[0]
        setGeneralSettingDetails(generalSettingsDetail)

        if (generalSettingsDetail?.street_address) {
          setLocationDetails(generalSettingsDetail?.street_address)
        }

        if (generalSettingsDetail?.latitude_map && generalSettingsDetail?.longitude_map) {
          setLatLng({ lat: Number(generalSettingsDetail?.latitude_map), lng: Number(generalSettingsDetail?.longitude_map) })
        }

        if (generalSettingsDetail?.latitude_marker && generalSettingsDetail?.longitude_marker) {
          setMLatLng({ mLat: Number(generalSettingsDetail?.latitude_marker), mLng: Number(generalSettingsDetail?.longitude_marker) })
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllCurrencies = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/currencies`,
    })
      .then(({ data }) => {
        setCurrencyOptions(singleCurrencyOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllCountries = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/countries",
    })
      .then(({ data }) => {
        setCountryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCountryDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/countries`,
    })
      .then(({ data }) => {
        const selectedCountry = data?.filter((country) => { return country?.id == id })
        setPhoneCountry(selectedCountry[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllProvinces = (countryId) => {
    if (parseInt(countryId) === 104) {
      Axios({
        method: "get",
        url: "/api/ecommerce/v1/countries/provinces",
      })
        .then(({ data }) => {
          setProvinceOptions(singleProvinceOptionsSelect(data))
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setProvinceOptions([{
        label: 'Please select...',
        value: ''
      }])
      formik.setValues((values) => ({
        ...values,
        store_province_id: "",
        store_city_id: "",
        store_subdistrict_id: "",
      }), false)
    }
  };

  const fetchAllCities = (countryId, provinceId) => {
    if (parseInt(countryId) === 104) {
      Axios({
        method: "get",
        url: `/api/ecommerce/v1/countries/cities/${provinceId}`,
      })
        .then(({ data }) => {
          setCityOptions(singleCityOptionsSelect(data))
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setCityOptions([{
        label: 'Please select...',
        value: ''
      }])
    }
  };

  const fetchAllSubDistricts = (countryId, cityId) => {
    if (parseInt(countryId) === 104) {
      Axios({
        method: "get",
        url: `/api/ecommerce/v1/countries/subdistricts/${cityId}`,
      })
        .then(({ data }) => {
          setSubDistrictOptions(singleSubDistrictOptionsSelect(data))
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setSubDistrictOptions([{
        label: 'Please select...',
        value: ''
      }])
    }
  };

  const handleEdit = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/settings/update`,
      data: payload,
    })
      .then(({ data }) => {
        navigate('/my-page')
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleInputValue = (values) => {
    const objectPayload = new FormData()

    objectPayload.append('shop_name', values.store_name)
    objectPayload.append('contact_person_name', values.store_person_name)
    objectPayload.append('contact_person_number', values.store_person_number)
    objectPayload.append('contact_person_email', values.store_person_email)
    objectPayload.append('address_details', values.store_address)
    objectPayload.append('postal_code', values.store_postal_code)
    objectPayload.append('shop_currency_id', parseInt(values.store_currency_id))
    objectPayload.append('country_id', parseInt(values.store_country_id))
    objectPayload.append('province_id', parseInt(values.store_province_id))
    objectPayload.append('city_id', parseInt(values.store_city_id))
    objectPayload.append('subdistrict_id', parseInt(values.store_subdistrict_id))
    objectPayload.append('street_address', locationDetails)
    objectPayload.append('latitude_marker', mLatLng?.mLat)
    objectPayload.append('longitude_marker', mLatLng?.mLng)
    objectPayload.append('latitude_map', latLng?.lat)
    objectPayload.append('longitude_map', latLng?.lng)

    if (Array.isArray(values.store_logo)) {
      objectPayload.append('file', values.store_logo[0])
    }

    return objectPayload
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handleEdit(inputPayload)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    files.forEach(file => {
      if (file && typeof file !== 'undefined') {
        attachments.push(file)
      }
    })
    formik.setValues((values) => ({
      ...values,
      store_logo: attachments
    }), false)
  }

  const handleImagesAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    files.forEach(file => {
      if (file && typeof file !== 'undefined') {
        attachments.push(file)
      }
    })
    formik.setValues((values) => ({
      ...values,
      store_logo: attachments
    }), false)
  }

  const handleFetchAutoComplete = async (query) => {
    return await Axios({ method: "get", url: `/api/ecommerce/v1/location/search/${query}`})
  }

  const handleFetchPlaceDetails = async (placeId) => {
    return await Axios({ method: "get", url: `/api/ecommerce/v1/location/details/${placeId}`})
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Edit General Settings</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            currencyOptions={currencyOptions}
            countryOptions={countryOptions}
            provinceOptions={provinceOptions}
            cityOptions={cityOptions}
            subDistrictOptions={subDistrictOptions}
            phoneCountry={phoneCountry}
            setOpenFilePreview={setOpenFilePreview}
            handleFileInput={handleImagesAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            latLng={latLng}
            setLatLng={setLatLng}
            mLatLng={mLatLng}
            setMLatLng={setMLatLng}
            locationDetails={locationDetails}
            setLocationDetails={setLocationDetails}
            fetchAutoComplete={handleFetchAutoComplete}
            fetchPlaceDetails={handleFetchPlaceDetails}
            selectedLocationVal={selectedLocationVal}
            setSelectedLocationVal={setSelectedLocationVal}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/my-page')}
            onSave={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default EditMyPage
