
import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CBadge,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CButton
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import { orderStatus } from 'src/helpers/status-badge'
import { getFullName } from 'src/helpers/text-format'
import { formatPriceNumber } from 'src/helpers/format-data'

const AppFirstDetails = ({
  orderDetails,
  shippingAddressDetails,
  setShowConfirmOrderStatus,
  setShowModalRequestShipment,
  handleCheckShippingHistory
}) => {
  const currency = useSelector((state) => state.currency)
  const orderMetadata = orderDetails?.products?.[0] ? JSON.parse(orderDetails?.products?.[0]?.metadata) : {}

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Order Status"
          subtitle={
            <CBadge
            color={orderStatus(orderDetails?.shipment_status ? orderDetails?.shipment_status : orderDetails?.payment_status)}
            >
              {orderDetails?.shipment_status ? orderDetails?.shipment_status?.split('_')?.join(' ')?.toString().toUpperCase() : orderDetails?.payment_status?.toString().toUpperCase()}
            </CBadge>
          }
        />
        {orderDetails?.shipping_number && orderDetails?.shipping_number !== '' && (
          <>
            <AppTextColumnDetails
              colSize="3"
              className="mb-3"
              title="Waybill Number"
              subtitle={orderDetails?.shipping_number}
            />
            <AppTextColumnDetails
              colSize="3"
              className="mb-3"
              title="Tracking Number"
              subtitle={orderDetails?.tracking_id}
            />
            <AppTextColumnDetails 
              colSize="2"
              className="mb-3 ms-auto"
              title=""
              subtitle={
                <CButton
                  color="dark"
                  onClick={() => handleCheckShippingHistory()}
                >
                  Track Shipping
                </CButton>
              }
            />
          </>
        )}
        {
          orderDetails?.payment_status === 'success' && !orderDetails?.shipping_number && (
            <AppTextColumnDetails
              colSize="2"
              className="mb-3 ms-auto"
              title=""
              subtitle={
                <CButton
                  color="dark"
                  onClick={() => setShowModalRequestShipment({show: true})}
                >
                  Request Shipment
                </CButton>
              }
            />
          )
        }
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase ID"
          subtitle={orderDetails?.transaction_id}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase Date / Time"
          subtitle={moment(orderDetails?.transaction_date).format('DD/MM/YYYY HH:mm')}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Discount / Promo Used"
          subtitle={orderMetadata?.voucher?.title ? `${orderMetadata?.voucher?.title} / ${orderMetadata?.voucher?.promo_code} (${formatPriceNumber(orderMetadata?.pricing?.discount, currency?.code)})` : '-'}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Status"
          subtitle={orderDetails?.guest_token ? 'GUEST' : 'MEMBER'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Name"
          // subtitle={getFullName(orderDetails?.user?.profile)}
          subtitle={`${shippingAddressDetails?.first_name || shippingAddressDetails?.contact_information_first_name} ${shippingAddressDetails?.last_name || shippingAddressDetails?.contact_information_last_name}`}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Amount Purchase"
          subtitle={formatPriceNumber(orderDetails?.transaction_amount, currency?.code)}
        />
      </CRow>
    </>
  )
}

export default AppFirstDetails
