import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'
import GoogleMaps from '../../GoogleMaps'

const AppLowerDetails = ({
  generalSettingDetails,
  countryDetails,
  subDistrictDetails,
  currencyDetails
}) => {

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Store Name"
          subtitle={generalSettingDetails?.shop_name}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Store Currency"
          subtitle={`${currencyDetails?.code} (${currencyDetails?.name})`}
        />
        <AppImageDetail
          colSize="4"
          title="Store Logo"
          productImages={generalSettingDetails?.logo_url}
        />
      </CRow>
      <CRow>
        <CFormLabel>
          Store Contact Number:
        </CFormLabel>
      </CRow>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Name"
          subtitle={generalSettingDetails?.contact_person_name}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Email"
          subtitle={generalSettingDetails?.contact_person_email}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Phone Number"
          subtitle={`${countryDetails?.phone_code ? `(${countryDetails?.phone_code}) ` : ""}${generalSettingDetails?.contact_person_number}`}
        />
      </CRow>
      <CRow>
        <CFormLabel>
          Store Location:
        </CFormLabel>
      </CRow>
      {
        generalSettingDetails?.latitude_map && 
        generalSettingDetails?.longitude_map &&
        generalSettingDetails?.latitude_marker &&
        generalSettingDetails?.longitude_marker && (
          <CRow className="mb-3">
            <GoogleMaps
              latLng={{ lat: Number(generalSettingDetails?.latitude_map), lng: Number(generalSettingDetails?.longitude_map) }}
              mLatLng={{ mLat: Number(generalSettingDetails?.latitude_marker), mLng: Number(generalSettingDetails?.longitude_marker) }}
              isEditable={false}
            />
          </CRow>
        )
      }
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Street Address"
          subtitle={generalSettingDetails?.street_address}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Address Details"
          subtitle={generalSettingDetails?.address_details}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Country"
          subtitle={countryDetails?.name}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Postal Code"
          subtitle={generalSettingDetails?.postal_code}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Province"
          subtitle={subDistrictDetails?.province}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="City"
          subtitle={subDistrictDetails?.city}
        />
        <AppTextColumnDetails
          className="mb-3"
          colSize="4"
          title="Subdistrict"
          subtitle={subDistrictDetails?.subdistrict_name}
        />
      </CRow>
    </>
  )
}

export default AppLowerDetails
