import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

// components
import {
  CSmartTable,
  CBadge,
  CButtonGroup,
  CButton,
  CImage,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CTooltip,
  CSmartPagination,
} from '@coreui/react-pro'

// icons
import ThreeDotsIconMenu from '../assets/icons/ThreeDotsOptionsIcon.png'

// styling
import { styleButtonVars5 } from './custom-styles/buttonStyles'

// helpers
import { productStatus } from 'src/helpers/status-badge'
import { orderStatus } from 'src/helpers/status-badge'
import { couponTypeText } from 'src/helpers/text-format'
import { useSelector } from 'react-redux'
import { formatPriceNumber } from 'src/helpers/format-data'

const AppTable = ({
  productListPage,
  categoryListPage,
  subCategoryListPage,
  brandListPage,
  orderListPage,
  customerListPage,
  couponListPage,
  setShowConfirmDelete,
  selectable,
  tableColumns,
  tableContent,
  contentPerPage,
  activeContentPage,
  contentCount,
  setActiveContentPage,
  tableFilter,
  searchContentFilter,
  setSearchContentFilter,
  setContentPerPage,
  columnContentSorter,
  setColumnContentSorter,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CSmartTable
        columns={tableColumns}
        // columnSorter={{
        //   external: true,
        //   resetable: true
        // }}
        sorterValue={columnContentSorter}
        // onSorterChange={(sorter) => setColumnContentSorter(sorter)}
        items={tableContent}
        itemsPerPage={contentPerPage}
        itemsPerPageSelect
        onItemsPerPageChange={(itemsPerPage) => setContentPerPage(itemsPerPage)}
        // pagination={{
        //   external: true,
        // }}
        // paginationProps={{
        //   align: 'center',
        //   activePage: activeContentPage,
        //   pages: Math.ceil(contentCount / contentPerPage) || 1,
        // }}
        // onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        tableProps={{
          className: 'app-table-content-list',
          hover: true,
          responsive: true,
          style: {
            cursor: 'pointer'
          }
        }}
        tableHeadProps={{
          className: 'app-table-header'
        }}
        selectable={selectable}
        // clickableRows
        onRowClick={(item) => handleOnRowClick(item)}
        tableFilter={tableFilter}
        tableFilterValue={searchContentFilter}
        tableFilterPlaceholder='Search...'
        tableFilterLabel='Search:'
        onTableFilterChange={(filter) => setSearchContentFilter(filter)}
        scopedColumns={{
          product_main_image: (item) => (
            <td>
              {item?.product_main_image
                ? (
                  <CImage
                    height={80}
                    width={80}
                    rounded
                    src={item?.product_main_image}
                    style={{ objectFit: "contain" }}
                  />
                )
                : 'Not Available'
              }
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={productStatus(item?.status)} shape="rounded-pill">
                {item?.status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          payment_status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={orderStatus(item?.shipment_status ? item?.shipment_status : item?.payment_status)} shape="rounded-pill">
                {item?.shipment_status ? item?.shipment_status?.split('_')?.join(' ')?.toString().toUpperCase() : item?.payment_status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          createdAt: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY')}
            </td>
          ),
          transaction_date: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}
            </td>
          ),
          description: (item) => (
            <td>
              {item?.description?.length > 70 ? `${item?.description?.slice(0, 70)}...` : item?.description}
            </td>
          ),
          promo_type: (item) => (
            <td>
              {couponTypeText(item?.promo_type)}
            </td>
          ),
          transaction_amount: (item) => (
            <td>
              {formatPriceNumber(item?.transaction_amount, currency?.code)}
            </td>
          ),
          price: (item) => (
            <td>
              {formatPriceNumber(item?.price, currency?.code)}
            </td>
          ),
          action: (item) => (
            <td>
              <CDropdown>
                <CButton
                  style={styleButtonVars5}
                  href={
                    productListPage ? `/products/details/${item.id}`
                      : categoryListPage ? `/products/category/details/${item.id}`
                        : subCategoryListPage ? `/products/sub-category/details/${item.id}`
                          : brandListPage ? `/products/brand/details/${item.id}`
                            : orderListPage ? `/orders/details/${item.id}`
                              : customerListPage ? `/customers/details/${item.id}`
                                : couponListPage ? `/coupons/details/${item.id}`
                                  : '#'
                  }
                >
                  View
                </CButton>
                {!Boolean(orderListPage) || Boolean(orderListPage && (item?.payment_status?.toLowerCase() === "settlement" || item?.payment_status?.toLowerCase() === "capture")) ? (
                  <CDropdownToggle
                    color="white"
                    caret={false}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      background: "#FFF",
                      // borderRadius: ".5rem",
                      borderBottomRightRadius: ".3rem",
                      borderTopRightRadius: ".3rem",
                    }}
                    split
                  >
                    <CImage fluid src={ThreeDotsIconMenu} />
                  </CDropdownToggle>
                ) : null}
                <CDropdownMenu>
                  {(productListPage || categoryListPage || subCategoryListPage || brandListPage || couponListPage) && (
                    <CDropdownItem
                      href={
                        productListPage ? `/products/edit/${item.id}`
                          : categoryListPage ? `/products/category/edit/${item.id}`
                            : subCategoryListPage ? `/products/sub-category/edit/${item.id}`
                              : brandListPage ? `/products/brand/edit/${item.id}`
                                : couponListPage ? `/coupons/edit/${item.id}`
                                  : '#'
                      }
                    >
                      Edit {
                        productListPage ? 'Product'
                          : categoryListPage ? 'Category'
                            : subCategoryListPage ? 'Sub Category'
                              : brandListPage ? 'Brand'
                                : couponListPage ? 'Coupon'
                                  : ''
                      }
                    </CDropdownItem>
                  )}

                  {!orderListPage && (
                    <CDropdownItem
                      onClick={() => setShowConfirmDelete({ show: true, item: item })}
                    >
                      Delete {
                        productListPage ? 'Product'
                          : categoryListPage ? 'Category'
                            : subCategoryListPage ? 'Sub Category'
                              : brandListPage ? 'Brand'
                                : customerListPage ? 'Customer'
                                  : couponListPage ? 'Coupon'
                                    : ''
                      }
                    </CDropdownItem>
                  )}
                  {/* {productListPage && (
                    <CDropdownItem>
                      Duplicate Product
                    </CDropdownItem>
                  )} */}
                  {orderListPage && (item?.payment_status?.toLowerCase() === "settlement" || item?.payment_status?.toLowerCase() === "capture") && (
                    <CDropdownItem
                      href={item?.invoice?.file_url}
                      download={`INVOICE ${item?.transaction_id}`}
                    >
                      Download Receipt
                    </CDropdownItem>
                  )}
                </CDropdownMenu>
              </CDropdown>
            </td>
          )
        }}
      />
      <CSmartPagination
        align="center"
        activePage={activeContentPage}
        pages={Math.ceil(contentCount / contentPerPage) || 1}
        onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        p
      />
    </>
  )
}

export default AppTable
